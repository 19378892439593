.leaderboard {
  @include font-size(1.6);
  border-collapse: collapse;
  color: white;
  width: 100%;

  tr {
    border: 1px solid white;

    td {
      padding: 0 15px;

      &:last-child {
        width: 100%;
      }
    }
  }

  &__avatar {
    display: block;
    height: auto;
    max-width: 67px;
  }

  &__pagination {
    color: white;
    display: block;
    font-weight: 600;
    margin-top: 10px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &--prev {
      float: left;
    }

    &--next {
      float: right;
    }
  }

  &--top {
    @include font-size(2);
    position: relative;
    z-index: 1;

    tr td {
      padding: 15px;
    }
  }

  &__bg {
    padding: 140px 40px 50px 25px;
    position: relative;

    &:before {
      background-image: url('/assets/images/table-bg.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: '';
      left: -32%;
      position: absolute;
      right: -70px;
      top: 100px;
    }
  }

  &__header {
    @include font-size(2.6);
    color: white;
    font-weight: 600;
    position: relative;
    z-index: 1;
  }
}

@include media-breakpoint-up(md) {
  .leaderboard {
    &__bg {
      &:before {
        right: 0;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .leaderboard {
    tr td:nth-child(3) {
      min-width: 150px;
    }
  }
}

@include media-breakpoint-up(xl) {
  .leaderboard {
    &__bg {
      padding: 140px 120px 50px 25px;
    }
  }
}
