.mobile-nav {
  background-color: $light-purple;
  display: none;
  padding-top: 20px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 998;

  &.js-open {
    display: block;
  }

  &__link {
    @include font-size(2);
    color: white;
    display: block;
    padding: 15px;
    text-align: center;
    text-decoration: none;
    width: 290px;

    &:hover {
      text-decoration: underline;
    }
  }

  &__close {
    max-width: 30px;
  }
}
