.login-popup {
  background-color: $light-purple;
  display: none;
  padding: 20px;
  position: absolute;
  top: -36px;
  right: 0;
  z-index: 999;

  &.js-open {
    display: block;
  }
}

@include media-breakpoint-up(lg) {
  .login-popup {
    top: 62px;
    right: 80px;

    &:before {
      border-color: transparent transparent $light-purple transparent;
      border-style: solid;
      border-width: 0 26px 35px 26px;
      content: '';
      height: 0;
      position: absolute;
      right: 10px;
      top: -35px;
      width: 0;
    }
  }
}

@include media-breakpoint-up(xl) {
  .login-popup {
    &:before {
      @include horizontal-centre();
    }
  }
}
