$light-purple: #764ac2;
$purple: #4c2b95;
$dark-purple: #2d1460;
$turquoise: #64eff8;
$cornflower-blue: #3be3e0;
$orange: #fccb05;
$blue-purple: #3d288d;
$pink: #ff00ff;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
