.header {
  align-items: center;
  background-color: rgba($dark-purple, 0.77);
  display: flex;
  justify-content: space-between;
  max-height: 97px;

  &__hmgcc-logo {
    height: auto;
    max-width: 200px;
  }

  &__right {
    align-items: center;
    display: flex;
    padding-right: 20px;
    position: relative;
  }

  &__nav-link {
    @include font-size(2.5);
    color: white;
    font-weight: 500;
    margin-right: 40px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }

  &__blk-box {
    display: none;
  }

  &__desktop-nav {
    display: none;
  }

  &__nav-toggle {
    background: transparent;
    border: none;
  }

  &__nav-toggle-bar {
    background-color: white;
    display: block;
    height: 4px;
    margin-bottom: 4px;
    width: 28px;
  }
}

@include media-breakpoint-up(md) {
  .header {
    &__hmgcc-logo {
      max-width: none;
    }

    &__right {
      padding-right: 40px;
    }

    &__blk-box {
      display: inline;
    }
  }
}

@include media-breakpoint-up(lg) {
  .header {
    &__nav-toggle {
      display: none;
    }

    &__desktop-nav {
      display: block;
    }
  }
}

@include media-breakpoint-up(xl) {
  .header {
    &__right {
      padding-right: 150px;
    }
  }
}
