/*Font sizes*/
@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}

@mixin background-image($url: '') {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @if $url != '' {
    background-image: url($url);
  }
}

@mixin vertical-centre() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-centre() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin vertical-horizontal-centre() {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin responsive-image() {
  width: 100%;
  height: auto;
}

@function colour($key) {
  @if map-has-key($colours, $key) {
    @return map-get($colours, $key);
  }

  @warn "Unknown `#{$key}` in $colours.";
  @return null;
}

@function shade($key) {
  @if map-has-key($shades, $key) {
    @return map-get($shades, $key);
  }

  @warn "Unknown `#{$key}` in $shades.";
  @return null;
}

@function breakpoint-next(
  $name,
  $breakpoints: $grid-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @return if(
    $n < length($breakpoint-names),
    nth($breakpoint-names, $n + 1),
    null
  );
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - 0.02px, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
