.copy {
  @include font-size(2.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  padding: 140px 40px 120px 40px;
  max-width: 1000px;

  a {
    color: $orange;

    &:hover {
      color: darken($orange, 15%);
    }
  }

  &__link {
    color: $orange;
    display: block;
    font-weight: 600;
    margin-right: 60px;
    text-transform: uppercase;

    &:last-child {
      margin-right: 0;
    }
  }

  &--getting-started {
    @include font-size(2);
    padding: 100px 40px 120px 40px;

    h2 {
      @include font-size(2.5);
      font-weight: 600;
    }
  }

  &--landing-page,
  &--landing-page-typed {
    max-width: 1100px;
  }
}

.challenge {
  margin-top: -40px;
  padding: 0 40px;

  &__image {
    display: block;
    margin-bottom: 20px;
    max-width: 100%;
  }

  &__image-container {
    max-width: 715px;
    position: relative;

    a {
      color: #060100;
      cursor: default;
      position: absolute;
      text-decoration: none;
      right: 40%;
      top: 46%;
    }
  }
}

#typed-1 {
  min-height: 464px;
}

#landing-auth {
  margin-top: -130px;
}

#landing-getting-started {
  margin-top: -120px;
  padding-top: 200px;
}

@include media-breakpoint-up(md) {
  .copy {
    padding: 140px 185px 120px 40px;

    &--getting-started {
      padding: 140px 100px 120px 40px;
    }
  }

  #landing-auth {
    margin-top: -100px;
  }

  #landing-getting-started {
    margin-top: -80px;
    padding-top: 140px;
  }
}

@include media-breakpoint-up(xl) {
  .copy {
    &__link {
      display: inline;
    }

    &--getting-started {
      padding: 140px 210px 120px 40px;
    }

    &--landing-page {
      padding: 85px 220px 85px 40px;
    }

    &--landing-page-typed {
      padding: 125px 220px 85px 40px;
    }
  }

  #landing-auth {
    margin-top: 40px;
  }

  #landing-getting-started {
    padding-top: 40px;
  }
}
