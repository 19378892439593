.dashboard-form {
  &__group {
    margin-bottom: 40px;
    position: relative;
  }

  &__input {
    @include font-size(2.5);
    border: none;
    color: $blue-purple;
    padding: 8px 15px;
    width: 100%;
  }

  &.js-submitting {
    .dashboard-form__group:after {
      animation: rotate 1.2s linear infinite;
      border: 3px solid rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      border-top-color: rgba(0, 0, 0, 1);
      content: '';
      height: 24px;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 24px;
      z-index: 2;
    }
  }
}
