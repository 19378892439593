.button {
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin-bottom: 5px;
  padding: 10px 35px;
  position: relative;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &--login {
    @include font-size(2.1);
    background-color: transparent;
    border: 1px solid white;
    color: white;
    font-weight: 600;

    &:hover {
      background-color: white;
      color: $purple;
    }
  }

  &--sign-up {
    @include font-size(2.5);
    background-color: $pink;
    border: 1px solid white;
    color: white;

    &:hover {
      background-color: darken($pink, 15%);
    }
  }
}
