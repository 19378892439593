.sign-up-form {
  @include font-size(2);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  margin: 0 auto;
  max-width: 1300px;
  padding: 120px 40px;

  &--forgot-password {
    margin: 100px auto 0 auto;
    padding: 60px 220px;
  }

  &__header {
    @include font-size(2.5);
    font-weight: 600;
  }

  &__group {
    margin-bottom: 15px;
  }

  &__input {
    @include font-size(2.5);
    border: none;
    color: $blue-purple;
    padding: 8px 15px;
    width: 100%;
  }

  &__buttons {
    margin-top: 40px;
  }

  &__avatar {
    cursor: pointer;
    display: block;
    margin-bottom: 15px;

    input {
      height: 0.1px;
      opacity: 0;
      position: absolute;
      width: 0.1px;

      &:checked ~ .sign-up-form__avatar-image {
        border: 3px solid $pink;
      }
    }
  }

  &__avatar-image {
    border: 3px solid transparent;
    border-radius: 5px;
    padding: 5px;
    pointer-events: none;
    width: 100%;
  }

  &__error {
    color: red;

    ul {
      list-style: none;
      padding: 0;
      margin: 0 0 30px 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .sign-up-form {
    &__left {
      padding-right: 60px;
    }
  }
}

@include media-breakpoint-up(lg) {
  .sign-up-form {
    padding: 120px 140px;
  }
}

@include media-breakpoint-up(xl) {
  .sign-up-form {
    padding: 120px 220px;
  }
}
