.dashboard {
  &__copy {
    @include font-size(2);
    color: white;
    height: 100%;
    margin-top: 60px;
    padding: 30px 40px 0 40px;
    position: relative;

    a {
      color: $orange;
    }

    * {
      position: relative;
      z-index: 1;
    }

    &:before {
      background-image: url('/assets/images/copy-bg-large.png');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      bottom: 0;
      content: '';
      left: 0;
      position: absolute;
      right: -30%;
      top: -12%;
    }

    h2 {
      @include font-size(2.1);
      font-weight: 600;
    }
  }
}

@include media-breakpoint-up(lg) {
  .dashboard {
    &__copy {
      padding: 100px 185px 30px 40px;

      &:before {
        top: -160px;
      }

      &--left {
        &:before {
          left: -15px;
          right: -177px;
        }
      }

      &--right {
        padding: 100px 40px 30px 40px;

        &:before {
          left: 0;
          right: -195px;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .dashboard {
    &__copy {
      &:before {
        top: -60px;
      }
    }
  }
}
