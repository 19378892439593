@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-ExtraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Source Code Pro';
  src: url('/assets/fonts/SourceCodePro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Mystery White';
  src: url('/assets/fonts/MysteryWhite.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
