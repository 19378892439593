.code-response {
  display: none;
  margin-top: 40px;
  min-height: 212px;
  padding: 40px 185px 40px 0;
  position: relative;

  &--fail:after {
    background-image: url('/assets/images/code-fail-bg.png');
  }

  &--success:after {
    background-image: url('/assets/images/code-success-bg.png');
  }

  &:after {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    content: '';
    left: -53px;
    min-height: 212px;
    position: absolute;
    right: -216px;
    top: 0;
  }

  &__image {
    position: absolute;
    right: 55px;
    top: 5px;

    &--tick {
      max-width: 130px;
      top: 7px;
    }
  }
}
