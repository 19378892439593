* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  @include font-size(1.6);
  font-family: 'Source Code Pro', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol {
  margin-bottom: 20px;
  margin-top: 0;
}

p.small {
  @include font-size(1.6);
}

.privacy-link {
  @include font-size(1.6);
  color: white;

  &:hover {
    text-decoration: none;
  }
}
