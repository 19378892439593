.login-form {
  * {
    transition: opacity 400ms ease;
  }

  &.js-submitting {
    * {
      opacity: 0.5;
      pointer-events: none;
    }

    &:after {
      animation: rotate 1.2s linear infinite;
      border: 5px solid rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      border-top-color: rgba(255, 255, 255, 1);
      content: '';
      height: 70px;
      left: 110px;
      position: absolute;
      top: 75px;
      width: 70px;
    }
  }

  &__group {
    margin-bottom: 20px;
  }

  &__input {
    @include font-size(2);
    border: 2px solid $light-purple;
    max-width: 250px;
    padding: 4px 10px;
    transition: border 400ms ease;

    &:focus {
      border: 2px solid $dark-purple;
      outline: none;
    }

    &.js-error {
      border: 2px solid red;
    }
  }

  &__forgot-password {
    @include font-size(1.4);
    color: white;
    display: block;
    margin-top: 8px;
    text-align: center;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__buttons {
    text-align: center;
  }

  &__error {
    color: red;
    margin-bottom: 10px;
  }

  ul {
    margin-bottom: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
